import React from 'react'
import { Link } from 'gatsby'
import { Layout, SEO } from '../components'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Error" />
    <section className="error-page">
      <div className="page-center">
        <span>404</span>
        <h3>죄송합니다. 페이지를 찾을 수 없습니다.</h3>
        <Link to="/" className="btn">
          홈으로 돌아가기
        </Link>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
